import {
  Box,
  Button,
  Container,
  Hidden,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import RegisterForm from "../../registerForm/RegisterForm";
import { TextBold } from "../../text/Text";
import Countdown from "../../countdown/Countdown";
import CarCardBooking from "../../carCardBooking/CarCardBooking";
import { Context } from "../../../context/Store";
import { functions } from "../../../database/firebase";
import { daysBetween } from "../../../utils/date";
import { useHistory } from "react-router-dom";
import LoginForm from "../../loginForm/LoginForm";
import StyledButton from "../../styledButton/StyledButton";
import { Redirect } from "react-router-dom";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { formatPrice } from "../../../utils/formating";
import StickyPrice from "../../stickyPrice/StickyPrice";
import FlexBoxColumn from "../../flexBox/FlexBoxColumn";
import UpdateInfoForm from "../../UpdateInfoForm/UpdateInfoForm";

const popUpInfo = (text) => (
  <FlexBoxColumn
    className="noscrollbar"
    alignItems="flex-start"
    mt="30px"
    fontSize="18px"
    overflow="scroll"
    width="100%"
  >
    <TextBold>{text}</TextBold>
  </FlexBoxColumn>
);
/**
 * Last step of booking site
 * @author Erik Jonasson
 * @todo Change dummy values
 */

const BookingFinish = () => {
  useEffect(() => {
    document.title = "Slutför bokning";
  }, []);

  const history = useHistory();
  const {
    car,
    bookingTime,
    bookingAdditions,
    reservation,
    customerInfo,
    setCustomerInfo,
    user,
    setPopUp,
  } = useContext(Context);

  const sm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);
  const [expandedView, setExpandedView] = useState(false);

  const [loginForm, setLoginFrom] = useState(false);
  const [changeInfo, setChangeInfo] = useState(false);

  const priceRef = useRef();
  const [yBreakPoint, setYBreakPoint] = useState();

  // This function calculates Y
  const getPosition = () => {
    const y = priceRef?.current?.offsetTop;
    setYBreakPoint(y);
  };

  // Get the position of the ref in the beginning
  useEffect(() => {
    getPosition();
    // console.log(expandedView);
  }, [priceRef.current?.offsetTop]);
  // }, [priceRef.current?.offsetTop, expandedView]);

  // Re-calculate Y of the ref when the window is resized by the user
  useEffect(() => {
    window.addEventListener("resize", getPosition);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("resize", getPosition);
    };
  }, []);
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        getPosition();
      },
      { passive: true },
    );

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", () => {
        getPosition();
      });
    };
  }, []);

  useEffect(() => {
    console.log("CustomerInfo: ", customerInfo);
  }, [user, customerInfo]);

  const submitBooking = async (inputFields) => {
    await finishBooking(inputFields);
  };

  const submitRegister = () => {
    console.log("Register");
  };

  const submitLogin = (InCustomerInfo) => {
    console.log("Login: ", InCustomerInfo);
    setCustomerInfo(InCustomerInfo);

    console.log(customerInfo);
  };

  const passwordReset = () => {
    console.log("Password reset");
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.text = `
      function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-1011210252/VvadCKmpvfkCEIywl-ID',
            'event_callback': callback
        });
        gtag('event', 'conversion', {
          'send_to': 'AW-1011210252/-CkpCOiYk_YZEIywl-ID',
          'value': 1.0,
          'currency': 'SEK',
          'transaction_id': ''
        });
        return false;
      }`;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const toDate = (dateNr) => {
    const date = new Date(dateNr);
    const year = date.getYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    const addPadding = (number) => {
      return number <= 9 ? "0" + number.toString() : number.toString();
    };

    // Month number is 0-indexed
    let dateString =
      1900 + year + "-" + addPadding(month + 1) + "-" + addPadding(day);

    dateString += " " + addPadding(hour) + ":" + addPadding(minute);

    return dateString;
  };

  const getNotLoggedInForm = () => {
    if (!loginForm) {
      return (
        <RegisterForm
          submitForm={submitBooking}
          bookingView
          loginInstead={() => {
            setLoginFrom(true);
          }}
        />
      );
    } else {
      return (
        <LoginForm
          submitForm={submitLogin}
          registerInstead={() => setLoginFrom(false)}
          passwordReset={passwordReset}
        />
      );
    }
  };

  const getLoggedInForm = () => {
    if (changeInfo) {
      return (
        <UpdateInfoForm
          onSubmitForm={() => {
            setChangeInfo(false);
          }}
        />
      );
    } else {
      return (
        <RegisterForm
          submitForm={submitBooking}
          bookingView
          changeInfo={() => {
            setChangeInfo(true);
            console.log("changeInfo");
          }}
          user
        />
      );
    }
  };

  const finishBooking = async (customerData) => {
    if (loading) return;
    setLoading(true);
    try {
      const makeBooking = functions.httpsCallable("booking-makeNew");
      const distance = bookingTime.distance;

      // from mail booking no towbar
      let tranmission = car.availableCars[0].transmission;
      const oneTransmission = car.availableCars.every(
        (val, i, arr) => val.transmission === arr[0].transmission,
      );
      if (!oneTransmission) {
        tranmission = "Automat/Manuell";
      }

      const bookingData = {
        carDocId: reservation.carId,
        priceGroup: car.priceGroup,
        startDate: Number(bookingTime.startDate),
        endDate: Number(bookingTime.endDate),
        startDateString: toDate(bookingTime.startDate),
        endDateString: toDate(bookingTime.endDate),
        personNumber: customerData.personNumber,
        distance: distance,
        damageReduction: bookingAdditions.damageReduction,
        additions: bookingAdditions.additionList,
        abroud: bookingAdditions.abroadPermit !== "",
        email: customerData.email,
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        now: Number(new Date()),
        nowText: toDate(new Date()),
        car: car,
        tranmission: tranmission,
        customerInfo: customerData,
        cost: formatPrice(bookingAdditions.cost),
        isPerson: customerData.isPerson,
      };

      await makeBooking(bookingData);

      setCustomerInfo(customerData);

      history.push("/bokning/konfirmation");
    } catch (error) {
      if (error.message) {
        setPopUp(popUpInfo(error.message));
      } else {
        setPopUp(
          popUpInfo(
            "Något gick fel, testa igen och kontakta folkes biluthyrning om problemet kvarstår",
          ),
        );
      }
    }
    setLoading(false);
  };

  if (!(car && bookingTime && reservation && bookingAdditions)) {
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="md" py="20px">
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        mt="30px"
        mb="40px"
      >
        <TextBold fontSize={["24px", "24px", "35px"]}>
          Slutför bokningsförfrågan
        </TextBold>
      </Box>
      {/* {sm && (
        <StickyPrice
          scrollBreakPoint={yBreakPoint - 95}
          cost={200}
        ></StickyPrice>
      )} */}
      <Box
        display="flex"
        flexDirection={sm ? "column-reverse" : "row"}
        alignItems={sm ? "center" : "stretch"}
        justifyContent={["center", "center", "space-between"]}
        mb={["60px", "90px"]}
        // ref={priceRef}
      >
        {user ? getLoggedInForm() : getNotLoggedInForm()}

        <Box ref={priceRef}></Box>
        {sm && (
          <StickyPrice
            scrollBreakPoint={yBreakPoint - 95}
            cost={bookingAdditions.cost}
            noTaxCost={bookingAdditions.noTaxCost}
          ></StickyPrice>
        )}
        <CarCardBooking
          car={car}
          bookingTime={bookingTime}
          bookingAdditions={bookingAdditions}
          viewExpanderTriggerFunction={setExpandedView}
        />
      </Box>
    </Container>
  );
};

export default BookingFinish;
